import { AnalyticsConfig, init } from '@hbf/analytics';

import { Config } from 'ha/config';

import { reportError } from 'ha/helpers/bugReporter/reportError';
import { STRICTLY_NECESSARY_DESTINATIONS } from 'ha/modules/Analytics/constants';

import {
  DEFAULT_COOKIE_CONSENT_CONFIG,
  LOCK_INTEGRATIONS_VERSION,
} from './constants';
import { Analytics, Callback, CookieConsent } from './types';

export const makeAnalyticsService = (
  config: Config['analytics'],
): Analytics => {
  const readyCallbacks: Callback[] = [];
  const userDependantCallbacks: Callback[] = [];
  let cookieConsentConfig: CookieConsent;

  let isReady = false;
  let userIdentified = false;

  const runCallbacks = (callbacks: Callback[]) => {
    callbacks.forEach(cb => cb(cookieConsentConfig));
    callbacks.length = 0;
  };

  return {
    init: ({ useBeacon, cookieConsent = DEFAULT_COOKIE_CONSENT_CONFIG }) => {
      cookieConsentConfig = cookieConsent;

      const { isConsentGiven, isCookieConsentEnabled } = cookieConsentConfig;

      if (!config.enabled) {
        return Promise.resolve();
      }

      const loadOptions: AnalyticsConfig['rudderstack']['loadOptions'] = {
        useBeacon,
        consentManagement: {
          enabled: isCookieConsentEnabled,
          provider: 'oneTrust',
        },
        lockIntegrationsVersion: LOCK_INTEGRATIONS_VERSION, // to avoid unexpected bugs with latest versions
      };

      if (isCookieConsentEnabled && !isConsentGiven) {
        loadOptions.integrations = STRICTLY_NECESSARY_DESTINATIONS;
        loadOptions.consentManagement = {
          enabled: false, // to avoid Rudderstack throwing "OneTrust resources are not accessible" error
        };
      }

      return init({
        rudderstack: {
          writeKey: config.rudderStackWriteKey,
          dataPlaneUrl: config.rudderStackDataPlaneURL,
          configUrl: config.rudderStackSourceConfig,
          cdnUrl: config.rudderStackCDN,
          loadOptions,
          onReady: () => {
            // for some reason some code relying on `getAnonymousId()`, `reset()`, ... does not behave predictably without this setTimeout
            setTimeout(() => {
              runCallbacks(readyCallbacks);

              isReady = true;
            });
          },
        },
      })
        ?.then(() => {
          // eslint-disable-next-line no-console
          console.log('Rudderstack initialized');
        })
        .catch(error => {
          reportError(new Error('Rudderstack - init', { cause: error }));
        });
    },
    onReady: (callback: Callback) => {
      if (isReady) {
        callback(cookieConsentConfig);
      } else {
        readyCallbacks.push(() => callback(cookieConsentConfig));
      }
    },
    onUserIdentified: (callback: Callback) => {
      if (userIdentified) {
        callback(cookieConsentConfig);
      } else {
        userDependantCallbacks.push(callback);
      }
    },
    userIdentificationComplete: () => {
      runCallbacks(userDependantCallbacks);
      userIdentified = true;
    },
  };
};
