export const socialUrls = {
  facebookUrl: 'https://www.facebook.com/HousingAnywherecom',
  twitterUrl: 'https://twitter.com/HousingAnywhere',
  linkedinUrl: 'https://linkedin.com/company/housinganywhere',
  youtubeUrl: 'https://www.youtube.com/user/HousingAnywhere',
  instagram: 'https://www.instagram.com/housinganywhere',
};

export const PAGE_TITLE_SUFFIX = ' | HousingAnywhere';

export enum ReviewPlatforms {
  TrustPilot = 'TrustPilot',
  GoogleReviews = 'GoogleReviews',
}

export const REVIEW_PLATFORM_URLS: Record<ReviewPlatforms, string> = {
  GoogleReviews: 'https://g.page/r/CRXR0tlGl_6yEAE/review',
  TrustPilot: 'https://www.trustpilot.com/evaluate/www.housinganywhere.com',
};
